import { Button } from "@asu/components-core";
import React, { FunctionComponent } from "react";
import { useGetProfileQuery, useSaveCommunicationPreferencesMutation } from "../api/uproApi";
import { updateEmailPreference } from "../state/communicationPreferencesSlice";
import { useAppDispatch, useAppSelector } from "../state/store";

const CommunicationPreferences: FunctionComponent = () => {
    const { data: profile, error, isLoading } = useGetProfileQuery();
    const dispatch = useAppDispatch();
    const emailPreferencesState = useAppSelector(state => state.communicationPreferences.email);
    const [saveCommunicationPreferences, { isSuccess: saveCommPrefSuccess, error: saveCommPrefError }] =
        useSaveCommunicationPreferencesMutation();

    const getUserByEmailPreferencesKey = (key: string) => {
        const sub = key.split(":")[0];
        return [profile!.eicProfile.user, ...profile!.eicProfile.asuriteAssociatedUsers].find(user => user.sub === sub);
    };

    async function save() {
        saveCommunicationPreferences({
            emailPreferences: Object.entries(emailPreferencesState!).map(([key, emailState]) => {
                const [userId, email] = key.split(":", 2);
                return {
                    userId,
                    email,
                    ...emailState,
                };
            }),
        });
    }

    return (
        <>
            <h2>Communication Preferences</h2>
            {isLoading && <div>Loading...</div>}
            {error && (
                <div>
                    Error... <pre>{JSON.stringify(error)}</pre>
                </div>
            )}

            {saveCommPrefSuccess && <div>✅ Preferences saved successfully</div>}

            {saveCommPrefError && (
                <div>
                    🚨 Error saving preferences: <pre>{JSON.stringify(saveCommPrefError)}</pre>
                </div>
            )}

            {emailPreferencesState && (
                <>
                    <h3>Emails</h3>
                    {Object.entries(emailPreferencesState).map(([key, emailState]) => {
                        const user = getUserByEmailPreferencesKey(key)!;
                        return (
                            <div key={key}>
                                <h4>
                                    {user.email.email} - {user.idp} - {user.cognitoUsername}
                                </h4>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`allowMarketingComms-${key}`}
                                        checked={emailState.allowMarketingComms}
                                        onChange={e =>
                                            dispatch(
                                                updateEmailPreference({
                                                    key,
                                                    pref: "allowMarketingComms",
                                                    checked: e.target.checked,
                                                }),
                                            )
                                        }
                                    />
                                    <label className="form-check-label" htmlFor={`allowMarketingComms-${key}`}>
                                        Allow Marketing Emails
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`allowPasswordResetComms-${key}`}
                                        checked={emailState.allowPasswordResetComms}
                                        onChange={e =>
                                            dispatch(
                                                updateEmailPreference({
                                                    key,
                                                    pref: "allowPasswordResetComms",
                                                    checked: e.target.checked,
                                                }),
                                            )
                                        }
                                    />
                                    <label className="form-check-label" htmlFor={`allowPasswordResetComms-${key}`}>
                                        Allow Password Reset Emails
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </>
            )}

            {profile && (
                <div>
                    <Button label="Save" color="gold" onClick={save} />
                </div>
            )}
        </>
    );
};

export default CommunicationPreferences;
