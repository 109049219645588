import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { uproApi } from "../api/uproApi";

export type CommnicationPreferencesState = {
    email?: Record<string, EmailCommunicationPreferencesState>;
};

export type EmailCommunicationPreferencesState = {
    allowMarketingComms: boolean;
    allowPasswordResetComms: boolean;
};

export type UpdatePreferencePayload = {
    key: string;
    pref: keyof EmailCommunicationPreferencesState;
    checked: boolean;
};

const initialState: CommnicationPreferencesState = {
    email: undefined,
};

const communicationPreferencesSlice = createSlice({
    name: "communicationPreferences",
    initialState,
    reducers: {
        updateEmailPreference: (state, action: PayloadAction<UpdatePreferencePayload>) => {
            state.email![action.payload.key][action.payload.pref] = action.payload.checked;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(isAnyOf(uproApi.endpoints.getProfile.matchFulfilled), (state, action) => {
            const profile = action.payload;
            state.email = [profile!.eicProfile.user, ...profile!.eicProfile.asuriteAssociatedUsers].reduce(
                (acc, user) => {
                    return {
                        ...acc,
                        [`${user.sub}:${user.email.email}`]: {
                            allowMarketingComms: user.email.allowMarketingComms,
                            allowPasswordResetComms: user.email.allowPasswordResetComms,
                        },
                    };
                },
                {} as Record<string, EmailCommunicationPreferencesState>,
            );
        });
    },
});

export const { updateEmailPreference } = communicationPreferencesSlice.actions;

export default communicationPreferencesSlice;
